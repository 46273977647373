import React from 'react'
import './GaugeChart1.css'
import Chart from "react-apexcharts";

export default function GuageChart1(props) {
  let state = {
    series: [props?.val],
    options: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radialBar: {
          // startAngle: -135,
          // endAngle: 225,
          startAngle: -135,
          endAngle: 135,
          hollow: {
            margin: 0,
            size: '60%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: props.textColor,
              fontSize: props.fontText
            },
            value: {
              formatter: function (val) {
                return (val) + props.valuegraph;
              },
              color: props.numColor,
              fontSize: props.numFontSize,
              show: true,
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [props.gradientColor],
          // gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 1000]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: [props.name],
    },
  };


  return (
    <>
      <div id="gaugechart" className='guage'>
        <Chart options={state.options} series={state.series} type="radialBar" height={props.heightgauge} />
      </div>
    </>
  )
}
