import { DateRangePicker } from 'rsuite';
import React, { useState, useEffect } from 'react'
import './MachineData.css'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import Header from '../Common/Header/Header';
import GuageChart1 from '../Common/Charts/GaugeChart1/GuageChart1'
import { GetMeterData } from '../../Service/MeterApi';
import Footer from '../Common/Footer/Footer';
import AreaChart1 from '../Common/Charts/AreaChart/AreaChart1';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MachineData = () => {

    const [value, setValue] = useState([]);

    let new1 = moment(value[0])?.format("DD-MM-YYYY")
    let new2 = moment(value[1])?.format("DD-MM-YYYY")

    let sdate = moment(value[0])?.format("YYYY-MM-DD")
    let edate = moment(value[1])?.format("YYYY-MM-DD")

    // console.log("epoch date start date-----", value)
    // console.log("epoch date end date-----", edate)
    let sendDate = {
        startDate: new1,
        endDate: new2
    }
    let dateback = {
        startDate: sdate,
        endDate: edate
    }

    const navigate = useNavigate()
    const location = useLocation()

    let MQTT_ID = new URLSearchParams(location.search).get("MQTT_ID")
    let roomName = location?.state?.roomName
    // console.log("These are the readings of data",reading.slice(-24))
    const [meterDashData, setMeterDashData] = useState([])
    let getarray = meterDashData?.map((array) => { return array?.meterReadings })

    // this api is goes to get api of meters data
    const gettingMeterData = async () => {
        let res = await GetMeterData(MQTT_ID)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setMeterDashData(res.data)

        }
    }
    useEffect(() => {
        if (MQTT_ID) {
            gettingMeterData()
            // setInterval(() => {
            // }, 5000);
        }
    }, [MQTT_ID])

    const settingDate = (event) => {
        if (event == null) {
            setValue([])
        } else {
            setValue(event)
        }
    }

    // the function is use to find the date between start date and last date
    const findByDate = () => {
        let startDate = dateback?.startDate
        let endDate = dateback?.endDate
        let sDate = new Date(startDate).getTime()
        let eDate = new Date(endDate).getTime()
        let epStartDate = new Date(sDate - 18000000).getTime()
        let epEndDate = new Date(eDate + 68000000).getTime()
        let filterDate = meterDashData?.map((meter) => {
            return {
                ...meter,
                meterReadings: meter?.meterReadings.filter((val) => {
                    let currenDate = new Date(val?.datetime).getTime()
                    if (currenDate >= epStartDate && currenDate <= epEndDate) {
                        return true
                    } else {
                        return false
                    }
                })
            }
        })
        // finddate(dispatch, dateback, MQTT_ID)
        navigate('/dashboard/report', { state: { roomName: roomName, date: sendDate, filterDate: filterDate } })
    }

    return (
        <>
            <div className="meterdatamain">
                <Header head={"MRS9000 TEMPERATURE & HUMIDITY MONITORING WITH REPORTING"} />
                <div className="machinedatamain">
                    <div className="machinetabledata">
                    </div>
                </div>
                <div className="gatherdateID">
                    <div className="timeID">
                        <div className="time">
                            {getarray[0]?.[getarray[0]?.length - 1]?.time + '\t | \t' + getarray[0]?.[getarray[0]?.length - 1]?.date}
                        </div>
                        <div className="DeviceID">
                            {meterDashData[0]?.Device_ID}
                        </div>
                    </div>

                    <div className="datepiker">
                        <DateRangePicker showOneCalendar className='rangepiker' onChange={(event) => settingDate(event)} value={value} placeholder="Start Date ~ End Date"
                            renderValue={(value) => {
                                return moment(value[0])?.format("DD-MM-YYYY") + ' ~ ' + moment(value[1])?.format("DD-MM-YYYY");
                            }} />
                        <button className='btnreport' type='submit' onClick={findByDate}> Report</button></div>
                </div>
                <div className="getherCharts">

                    <div className="guageChartcontainer">

                        <GuageChart1 val={getarray[0]?.[getarray[0]?.length - 1]?.temp} name='Temperatre' numColor='rgb(211, 84, 0 )' textColor='rgba(237, 187, 153, 1)' valuegraph="°C" gradientColor='rgba(237, 187, 153, 0.7)'  heightgauge={200} fontText="13px" numFontSize='18px'/>
                        <GuageChart1 val={getarray[0]?.[getarray[0]?.length - 1]?.humidity} name='Humidity' numColor='rgb(41, 128, 185 )' textColor='rgba(41, 128, 185 ,0.7)' valuegraph="%" gradientColor='rgba(41, 128, 185 ,0.7)' heightgauge={200}  fontText="13px" numFontSize='18px' />
                    </div>
                    <div className="linechartcontainer">
                        <AreaChart1 graphTime={getarray[0]?.slice(-24)?.map((time) => time?.time)} tempvalue={getarray[0]?.slice(-24)?.map((temp) => temp?.temp)} humidvalue={getarray[0]?.slice(-24)?.map((humid) => humid?.humidity)} />
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer />
        </>
    )
}
export default MachineData
