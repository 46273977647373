import React from 'react'
import './AreaChart1.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


export default function AreaChart1(props) {
        // =------------Starting the Graph Data Here
        const options = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    // position: 'top' as const,
                    position: 'top',
                },
                // title: {
                //     display: true,
                //     text: 'Temperature & Humidity Data',
                // },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    max: 100
                }
            }
    
        };
    

        const labels = props.graphTime
        const labelTemp = 'Temperature'
        const labelHumid = 'Humidity'
        const data = {
            labels,
            datasets: [
                {
                    fill: true,
                    label: labelTemp,
                    // label: 'Temperature' + '\t' + meterDashData[meterDashData.length - 1]?.value?.temp + '°C ',
                    // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                    //   data: [10,42,13,74,25,23,54,65],
    
                    // data: meterDashData[0]?.meterReadings.slice(-24).map((ddd)=>ddd?.temp),
                    data: props.tempvalue,
                    borderColor: 'rgb(211, 84, 0 )',
                    backgroundColor: 'rgba(237, 187, 153, 0.7)',
                    borderJoinStyle: 'round',
                },
                {
                    fill: true,
                    label: labelHumid,
                    //   data: [10,42,13,74,25,23,54,65],
                    data: props.humidvalue,
                    borderColor: 'rgb(41, 128, 185 )',
                    backgroundColor: 'rgba(169, 204, 227, 0.7)',
                    radius: 3,
                    borderJoinStyle: 'round',
                },
    
            ],
        };
  return (
    <>
    <Line className='linechartt' options={options} data={data} />
    </>
  )
}
