import React, { useEffect, useState } from 'react'
import { GetMachineData } from '../../Service/MachineApi'
import { GetAllMeterData } from '../../Service/MeterApi';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Machine.css'
import Header from '../Common/Header/Header'

import GuageChart1 from '../Common/Charts/GaugeChart1/GuageChart1';
import { useSelector } from 'react-redux';

export default function Building() {
    const navigate = useNavigate()

    const [machinedata, setMachinedata] = useState([])

    const gettingBuildingData = async () => {
        // let res = await GetMachineData()
        let res = await GetAllMeterData()

        if (res.error != null) {
            toast.error(res.error)
        } else {
            setMachinedata(res.data)
        }
    }
    useEffect(() => {
        gettingBuildingData()
        // setMachinedata(machineData1?.dateData)
    }, [])
    return (
        <>
            <div className="machineMainContainer">
                <Header head={"MRS9000 TEMPERATURE & HUMIDITY MONITORING WITH REPORTING"} />
                <div className="machineCard">
                    {
                        machinedata.map((bdata, i) => {
                            const meterRead = bdata?.meterReadings
                            return (
                                <div key={i}
                                    onClick={() => {
                                        navigate(`/dashboard/Dep?MQTT_ID=${bdata.MQTT_ID}`, { state: { roomName: bdata.machineName, reading: bdata.meterReadings } })
                                    }}
                                    className="machinemain">{bdata?.machineName} <br /> {bdata?.Device_ID}
                                    <div className="gaugechartmachine">
                                        <div className="gaugemachine1">
                                            {/* <div className="temp">Temp. : {meterRead[meterRead.length - 1]?.temp}</div> */}
                                            <GuageChart1 className="gaugechartclass" val={meterRead[meterRead.length - 1]?.temp} name={meterRead[meterRead.length - 1]?.temp + " °C"} numColor='rgb(211, 84, 0 )' textColor='rgba(0, 0, 255, 1)' valuegraph="" gradientColor='rgba(237, 187, 153, 0.7)' heightgauge={120} numFontSize = '0px'/>
                                        </div>
                                        <div className="gaugemachine2">
                                            {/* <div className="humid">Humidity : {meterRead[meterRead.length - 1]?.humidity}</div> */}
                                            <GuageChart1 className="gaugechartclass" val={meterRead[meterRead.length - 1]?.humidity} name={meterRead[meterRead.length - 1]?.humidity + " %"} numColor='rgb(41, 128, 185 )' textColor='rgba(0, 0, 255, 1)' valuegraph="" gradientColor='rgba(41, 128, 185, 0.7)' heightgauge={120} numFontSize = '0px'/>
                                        </div>
                                    </div>
                                    <div className="datetimemachinepage">{meterRead[meterRead.length - 1]?.time} |  {meterRead[meterRead.length - 1]?.date}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <ToastContainer />
            </div>
        </>
    )
}
